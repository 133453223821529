<template>
	<div class="tiptap-container">
		<v-toolbar v-if="editor && !$vuetify.breakpoint.xs" dense>
			<v-toolbar-items>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-select v-on="on" v-bind="attrs" :items="$options.TextTypeOptions" @change="setTextType" :value="textType" class="d-inline-flex" hide-details solo>
							<template v-slot:item="{ item }">
								<component :is="item.value">
									{{item.text}}
								</component>
							</template>
						</v-select>
					</template>
					<span>Text Type</span>
				</v-tooltip>
				<v-divider vertical class="ml-3"></v-divider>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().toggleBold().run()" :color="editor.isActive('bold') ? 'primary' : ''">
							<v-icon small>fas fa-bold</v-icon>
						</v-btn>
					</template>
					<span>Bold</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().toggleItalic().run()" :color="editor.isActive('italic') ? 'primary' : ''">
							<v-icon small>fas fa-italic</v-icon>
						</v-btn>
					</template>
					<span>Italic</span>
				</v-tooltip>
				<!-- <v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().toggleUnderline().run()" :color="editor.isActive('underline') ? 'primary' : ''">
							<v-icon small>fas fa-underline</v-icon>
						</v-btn>
					</template>
					<span>Underline</span>
				</v-tooltip> -->
				<!-- <v-tooltip v-if="!editor.isActive('table') && !editor.isActive('taskItem')" top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">
							<v-icon small>fas fa-table</v-icon>
						</v-btn>
					</template>
					<span>Insert Table</span>
				</v-tooltip> -->
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().toggleStrike().run()" :color="editor.isActive('strike') ? 'primary' : ''">
							<v-icon small>fas fa-strikethrough</v-icon>
						</v-btn>
					</template>
					<span>Strikethrough</span>
				</v-tooltip>
				<template v-if="!markdown">
					<v-divider vertical class="mx-3"></v-divider>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-select v-on="on" v-bind="attrs" :items="$options.TextAlignmentOptions" @change="setTextAlignment" :value="textAlignment" class="d-inline-flex" hide-details solo :width="50">
								<template v-slot:selection="{ item }">
									<v-icon>{{item.icon}}</v-icon>
								</template>
								<template v-slot:item="{ item }">
									<v-icon left>{{item.icon}}</v-icon> <span>{{item.text}}</span>
								</template>
							</v-select>
						</template>
						<span>Text Alignment</span>
					</v-tooltip>
				</template>
				<v-divider vertical class="mr-3"></v-divider>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="manageLink" :color="editor.isActive('link') ? 'primary' : ''">
							<v-icon small>fas fa-link</v-icon>
						</v-btn>
					</template>
					<span>Link</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().toggleBulletList().run()" :color="editor.isActive('bulletList') ? 'primary' : ''">
							<v-icon small>fas fa-list-ul</v-icon>
						</v-btn>
					</template>
					<span>Bullet List</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().toggleOrderedList().run()" :color="editor.isActive('orderedList') ? 'primary' : ''">
							<v-icon small>fas fa-list-ol</v-icon>
						</v-btn>
					</template>
					<span>Ordered List</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().toggleTaskList().run()" :color="editor.isActive('taskList') ? 'primary' : ''">
							<v-icon small>fas fa-clipboard-list</v-icon>
						</v-btn>
					</template>
					<span>Task List</span>
				</v-tooltip>
			</v-toolbar-items>
		</v-toolbar>
		<v-sheet class="note-content">
			<editor-content :editor="editor" />
		</v-sheet>
		<bubble-menu v-if="editor" :shouldShow="shouldShow" :editor="editor">
			<v-sheet dark class="elevation-5">
				<v-item-group>
					<v-item>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().addColumnBefore().run()">
									<v-icon small>fas fa-arrow-to-left</v-icon>
								</v-btn>
							</template>
							<span>Add column to the left</span>
						</v-tooltip>
					</v-item>
					<v-item>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().addColumnAfter().run()">
									<v-icon small>fas fa-arrow-to-right</v-icon>
								</v-btn>
							</template>
							<span>Add column to the right</span>
						</v-tooltip>
					</v-item>
					<v-item>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().addRowBefore().run()">
									<v-icon small>fas fa-arrow-to-top</v-icon>
								</v-btn>
							</template>
							<span>Add row above</span>
						</v-tooltip>
					</v-item>
					<v-item>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().addRowAfter().run()">
									<v-icon small>fas fa-arrow-to-bottom</v-icon>
								</v-btn>
							</template>
							<span>Add row below</span>
						</v-tooltip>
					</v-item>
					<v-item>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().deleteColumn().run()">
									<v-icon small>fas fa-border-center-v</v-icon>
								</v-btn>
							</template>
							<span>Delete column</span>
						</v-tooltip>
					</v-item>
					<v-item>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on" @click="editor.chain().focus().deleteRow().run()">
									<v-icon small>fas fa-border-center-h</v-icon>
								</v-btn>
							</template>
							<span>Delete row</span>
						</v-tooltip>
					</v-item>
				</v-item-group>
			</v-sheet>
		</bubble-menu>
	</div>
</template>

<script>
	import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
	import Table from "@tiptap/extension-table";
	import TableRow from "@tiptap/extension-table-row";
	import TableCell from "@tiptap/extension-table-cell";
	import TableHeader from "@tiptap/extension-table-header";
	import StarterKit from "@tiptap/starter-kit";
	import Underline from "@tiptap/extension-underline";
	import TaskList from "@tiptap/extension-task-list";
	import TaskItem from "@tiptap/extension-task-item";
	import Link from "@tiptap/extension-link";

	import turndownService from './tiptap/turndown-service';
	import { tiptapMarkdownRenderer } from './tiptap/markdown-it';

	export default {
		components: {
			EditorContent,
			BubbleMenu,
		},

		TextTypeOptions: [
			{ text: "Paragraph", value: "p" },
			{ text: "Heading 1", value: "h1" },
			{ text: "Heading 2", value: "h2" },
			{ text: "Heading 3", value: "h3" },
		],

		TextAlignmentOptions: [
			{ text: "Left", icon: "fa fa-align-left", value: "left" },
			{ text: "Right", icon: "fa fa-align-right", value: "right" },
			{ text: "Center", icon: "fa fa-align-center", value: "center" },
			{ text: "Justify", icon: "fa fa-align-justify", value: "justify" },
		],

		props: {
			markdown: {
				type: Boolean,
				default: false,
			},
			value: {
				type: String,
				required: true,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
		},

		data() {
			return {
				rawMarkdown: this.markdown,

				editor: new Editor({
					content: "",
					extensions: [
						StarterKit.configure({
							heading: {
								levels: [1, 2, 3],
							},
						}),
						Table.configure({
							resizable: true,
						}),
						TableRow,
						TableCell,
						TableHeader,
						Underline,
						TaskList,
						TaskItem,
						Link.configure({
							openOnClick: false
						})
					],
				}),
			};
		},

		computed: {
			textType() {
				switch (true) {
					case this.editor.isActive("paragraph"):
						return "p";
					case this.editor.isActive("heading", { level: 1 }):
						return "h1";
					case this.editor.isActive("heading", { level: 2 }):
						return "h2";
					case this.editor.isActive("heading", { level: 3 }):
						return "h3";
				}
			},

			textAlignment() {
				switch (true) {
					case this.editor.isActive({ textAlign: "left" }):
						return "left";
					case this.editor.isActive({ textAlign: "right" }):
						return "right";
					case this.editor.isActive({ textAlign: "center" }):
						return "center";
					case this.editor.isActive({ textAlign: "justify" }):
						return "justify";
				}
			},

			content() {
				let result = this.editor.getHTML();

				if (this.rawMarkdown) {
					result = turndownService.turndown(result);
				}

				return result;
			},
		},

		beforeDestroy() {
			this.editor.destroy();
		},

		methods: {
			shouldShow({ editor }) {
				return editor.isActive("table");
			},

			setTextType(type) {
				let command = this.editor.chain().focus();

				switch (type) {
					case "p":
						command = command.setParagraph();
						break;

					case "h1":
					case "h2":
					case "h3":
						let level = parseInt(type.substr(1));
						command = command.setHeading({ level });
						break;
				}

				command.run();
			},

			setTextAlignment(type) {
				this.editor.chain().focus().setTextAlign(type).run();
			},

			manageLink() {
				// const editorState = this.editor.view.state;
				// const { $from, $to } = editorState.selection;

				// const text = $from.nodeBefore.textContent + $to.nodeAfter.textContent;
				const link = this.editor.getAttributes('link').href || '';

				this.$modalService.create('tiptapLinkAddEdit', { link })
					.on('save', (e, { link }) => {
						console.log('save', link);
						this.editor
							.chain()
							.focus()
							.extendMarkRange('link')
							.setLink({ href: link })
							.run();

						// $from.parent.content.content[0].text = text;
					})
					.on('delete', () => {
						this.editor
							.chain()
							.focus()
							.extendMarkRange('link')
							.unsetLink()
							.run()
					});
			}
		},

		watch: {
			value: {
				immediate: true,
				handler(to) {
					if (to != this.content) {
						if (this.rawMarkdown) {
							to = tiptapMarkdownRenderer.render(to);
						}

						this.editor.commands.setContent(to);
					}
				},
			},

			disabled: {
				immediate: true,
				handler(to) {
					this.editor.setEditable(!to);
				},
			},

			content(to) {
				if (to != this.value) {
					this.$emit("input", to);
				}
			},
		},
	};
</script>

<style lang="scss">
	.ProseMirror {
		padding: 1rem;

		&:focus-visible {
			outline: none;
		}

		table {
			border-collapse: collapse;
			table-layout: fixed;
			width: 100%;
			margin: 0;
			overflow: hidden;

			td,
			th {
				min-width: 1em;
				border: 2px solid #ced4da;
				padding: 3px 5px;
				vertical-align: top;
				box-sizing: border-box;
				position: relative;

				> * {
					margin-bottom: 0;
				}
			}

			th {
				font-weight: bold;
				text-align: left;
				background-color: #ccc;

				.theme--dark & {
					background-color: #555;
				}
			}

			.selectedCell:after {
				z-index: 2;
				position: absolute;
				content: "";
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: rgba(200, 200, 255, 0.4);
				pointer-events: none;
			}

			.column-resize-handle {
				position: absolute;
				right: -2px;
				top: 0;
				bottom: -2px;
				width: 4px;
				background-color: #adf;
				pointer-events: none;
			}
		}
	}

	.tableWrapper {
		margin: 1rem 0;
		overflow-x: auto;
	}

	.resize-cursor {
		cursor: ew-resize;
		cursor: col-resize;
	}

	ul[data-type="taskList"] {
		list-style: none;
		padding: 0;

		li {
			display: flex;
			align-items: center;

			> label {
				flex: 0 0 auto;
				margin-right: 0.5rem;
				user-select: none;
			}

			> div {
				flex: 1 1 auto;
			}
		}
	}
</style>