var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tiptap-container"},[(_vm.editor && !_vm.$vuetify.breakpoint.xs)?_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({staticClass:"d-inline-flex",attrs:{"items":_vm.$options.TextTypeOptions,"value":_vm.textType,"hide-details":"","solo":""},on:{"change":_vm.setTextType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(item.value,{tag:"component"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,true)},'v-select',attrs,false),on))]}}],null,false,1129198902)},[_c('span',[_vm._v("Text Type")])]),_c('v-divider',{staticClass:"ml-3",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.editor.isActive('bold') ? 'primary' : ''},on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-bold")])],1)]}}],null,false,1490343465)},[_c('span',[_vm._v("Bold")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.editor.isActive('italic') ? 'primary' : ''},on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-italic")])],1)]}}],null,false,3024133206)},[_c('span',[_vm._v("Italic")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.editor.isActive('strike') ? 'primary' : ''},on:{"click":function($event){_vm.editor.chain().focus().toggleStrike().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-strikethrough")])],1)]}}],null,false,2880718085)},[_c('span',[_vm._v("Strikethrough")])]),(!_vm.markdown)?[_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({staticClass:"d-inline-flex",attrs:{"items":_vm.$options.TextAlignmentOptions,"value":_vm.textAlignment,"hide-details":"","solo":"","width":50},on:{"change":_vm.setTextAlignment},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.text))])]}}],null,true)},'v-select',attrs,false),on))]}}],null,false,3519861513)},[_c('span',[_vm._v("Text Alignment")])])]:_vm._e(),_c('v-divider',{staticClass:"mr-3",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.editor.isActive('link') ? 'primary' : ''},on:{"click":_vm.manageLink}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-link")])],1)]}}],null,false,1853333403)},[_c('span',[_vm._v("Link")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.editor.isActive('bulletList') ? 'primary' : ''},on:{"click":function($event){_vm.editor.chain().focus().toggleBulletList().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-list-ul")])],1)]}}],null,false,2369062810)},[_c('span',[_vm._v("Bullet List")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.editor.isActive('orderedList') ? 'primary' : ''},on:{"click":function($event){_vm.editor.chain().focus().toggleOrderedList().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-list-ol")])],1)]}}],null,false,1776012480)},[_c('span',[_vm._v("Ordered List")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.editor.isActive('taskList') ? 'primary' : ''},on:{"click":function($event){_vm.editor.chain().focus().toggleTaskList().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-clipboard-list")])],1)]}}],null,false,1972083567)},[_c('span',[_vm._v("Task List")])])],2)],1):_vm._e(),_c('v-sheet',{staticClass:"note-content"},[_c('editor-content',{attrs:{"editor":_vm.editor}})],1),(_vm.editor)?_c('bubble-menu',{attrs:{"shouldShow":_vm.shouldShow,"editor":_vm.editor}},[_c('v-sheet',{staticClass:"elevation-5",attrs:{"dark":""}},[_c('v-item-group',[_c('v-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().addColumnBefore().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-to-left")])],1)]}}],null,false,3999640819)},[_c('span',[_vm._v("Add column to the left")])])],1),_c('v-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().addColumnAfter().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-to-right")])],1)]}}],null,false,1244810005)},[_c('span',[_vm._v("Add column to the right")])])],1),_c('v-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().addRowBefore().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-to-top")])],1)]}}],null,false,2017361343)},[_c('span',[_vm._v("Add row above")])])],1),_c('v-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().addRowAfter().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-to-bottom")])],1)]}}],null,false,3050244934)},[_c('span',[_vm._v("Add row below")])])],1),_c('v-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().deleteColumn().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-border-center-v")])],1)]}}],null,false,2566083898)},[_c('span',[_vm._v("Delete column")])])],1),_c('v-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().deleteRow().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-border-center-h")])],1)]}}],null,false,834115960)},[_c('span',[_vm._v("Delete row")])])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }