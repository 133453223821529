<template>
	<v-dialog :value="true" min-width="500" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title v-html="modalTitle"></v-card-title>

			<v-card-text>
				<v-alert v-if="modalErrorMessage" color="error" border="bottom" class="mb-3" v-html="modalErrorMessage"></v-alert>

				<!-- <v-btn-toggle v-model="toggle_exclusive" dense mandatory class="mb-3" color="primary">
					<v-btn><v-icon small class="mr-2">fab fa-markdown</v-icon> Markdown</v-btn>
					<v-btn>WYSIWYM</v-btn>
				</v-btn-toggle> -->

				<tip-tap :markdown="true" v-model="content" :disabled="saving" style="border: 1px solid var(--v-text-color-base)"></tip-tap>

			</v-card-text>

			<v-card-actions>
				<v-btn @click="modal.trigger('close')" :disabled="saving" class="ml-auto">Cancel</v-btn>
				<v-btn color="primary" @click="processForm" :disabled="saving" :loading="saving">
					{{realSaveBtnText}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

	import moment from "moment";
	import TipTap from "@/components/TipTap.vue";

	export default {
		name: "noteAddEdit",

		components: {
			TipTap,
		},

		props: {
			modal: {
				type: Object,
				required: true,
			},
			objType: {
				type: String,
			},
			objId: {
				type: [Number, String],
			},
			note: {
				type: Object,
			},
			noteType: {
				type: [String, Boolean],
				default: "internal",
			},
			title: {
				type: String,
				default: "",
			},
			saveBtnText: {
				type: String,
			}
		},

		data() {
			return {
				toggle_exclusive: 0,
				note_type: this.getObjectProp("note_type") || this.noteType || "internal",
				content: this.getObjectProp("content") || '',
				date: this.$options.filters.clientDate(
					this.getObjectProp("date") || false,
					"today"
				),
				mailto: [],
				menu: false,
				search: null,
				modalErrorMessage: false,
				saving: false,
			};
		},

		computed: {
			modalTitle() {
				if (!this.title) {
					return this.note ? "Edit Note" : "Add Note";
				}

				return this.title;
			},
			realSaveBtnText() {
				if (!this.saveBtnText) {
					return this.note ? "Update" : "Create";
				}

				return this.saveBtnText;
			},
			formModel() {
				let model = {
					note_type: this.note_type,
					content: this.content,
					date: this.date + moment().format(" HH:mm:ss"),
					mailto: this.mailto,
				};

				for (let field in model) {
					if (!model[field]) {
						delete model[field];
					}
				}

				return model;
			},
		},

		methods: {
			processForm() {
				this.saving = true;

				let method, api;
				if (this.note) {
					method = "put";
					api = `/api/note/${this.note.id}`;
				} else {
					method = "post";
					api = `/api/${this.objType}/${this.objId}/notes`;
				}

				this.$xhrRequest.send(method, api, this.formModel).then(
					(note) => {
						let noteType =
							"reminder deadline".indexOf(note.note_type) >= 0
								? note.note_type
								: "Note";
						let message =
							method === "post"
								? `Created a new ${noteType}`
								: `The ${noteType} has been updated.`;
						this.$snotify.success(message, "Success!");
						this.modal.trigger("save close", note);
					},
					(xhr) => {
						this.saving = false;
						let errors = this.$insight.helpers.getRequestErrors(xhr);
						errors = this.$insight.helpers.flattenRequestErrors(errors);

						this.modalErrorMessage = errors.join("<br>");
						this.$snotify.error("Error!", "Error saving note");
					}
				);
			},

			getObjectProp(name) {
				if (this.note && this.note[name]) {
					return this.note[name];
				}

				return "";
			},
		},
	};
</script>
