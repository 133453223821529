import TurndownService from "turndown";

const turndownService = new TurndownService({
	headingStyle: "atx",
});

turndownService.addRule('task-item', {
	filter: (node) => {
		return node.nodeName == 'LI' && node.getAttribute('data-type') == 'taskItem';
	},
	replacement: function(content, node) {
		let checked = node.getAttribute('data-checked') === 'true',
			innerContent = turndownService.turndown(node.innerHTML).replace(/\n/g, '\n\t');

		return `- [${checked ? 'x' : ' '}] ` + innerContent + '\n';
	}
});

turndownService.addRule('strikethrough', {
	filter: 's',
	replacement: function(content) {
		return `~~${content}~~`;
	}
})

// let renderedDivider = false;

// turndownService.addRule('table-root', {
// 	filter: (node) => {
// 		return node.nodeName == 'TABLE' && node.getAttribute('data-type') == 'taskItem';
// 	},
// 	replacement: function(content, node) {
// 		let checked = node.getAttribute('data-checked') === 'true',
// 			innerContent = turndownService.turndown(node.innerHTML).replace(/\n/g, '\n\t');

// 		return `- [${checked ? 'x' : ' '}] ` + innerContent + '\n';
// 	}
// });

export default turndownService;